export default [
  {
    inputs: [
      {
        internalType: 'contract IDelegationManager',
        name: '_delegationManager',
        type: 'address',
      },
      {
        internalType: 'contract IStrategyManager',
        name: '_strategyManager',
        type: 'address',
      },
      {
        internalType: 'uint32',
        name: '_CALCULATION_INTERVAL_SECONDS',
        type: 'uint32',
      },
      { internalType: 'uint32', name: '_MAX_REWARDS_DURATION', type: 'uint32' },
      {
        internalType: 'uint32',
        name: '_MAX_RETROACTIVE_LENGTH',
        type: 'uint32',
      },
      { internalType: 'uint32', name: '_MAX_FUTURE_LENGTH', type: 'uint32' },
      {
        internalType: 'uint32',
        name: '__GENESIS_REWARDS_TIMESTAMP',
        type: 'uint32',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'avs', type: 'address' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'submissionNonce',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'rewardsSubmissionHash',
        type: 'bytes32',
      },
      {
        components: [
          {
            components: [
              {
                internalType: 'contract IStrategy',
                name: 'strategy',
                type: 'address',
              },
              { internalType: 'uint96', name: 'multiplier', type: 'uint96' },
            ],
            internalType: 'struct IRewardsCoordinator.StrategyAndMultiplier[]',
            name: 'strategiesAndMultipliers',
            type: 'tuple[]',
          },
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint32', name: 'startTimestamp', type: 'uint32' },
          { internalType: 'uint32', name: 'duration', type: 'uint32' },
        ],
        indexed: false,
        internalType: 'struct IRewardsCoordinator.RewardsSubmission',
        name: 'rewardsSubmission',
        type: 'tuple',
      },
    ],
    name: 'AVSRewardsSubmissionCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint32',
        name: 'oldActivationDelay',
        type: 'uint32',
      },
      {
        indexed: false,
        internalType: 'uint32',
        name: 'newActivationDelay',
        type: 'uint32',
      },
    ],
    name: 'ActivationDelaySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'earner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'oldClaimer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'claimer',
        type: 'address',
      },
    ],
    name: 'ClaimerForSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint32',
        name: 'rootIndex',
        type: 'uint32',
      },
      { indexed: true, internalType: 'bytes32', name: 'root', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'uint32',
        name: 'rewardsCalculationEndTimestamp',
        type: 'uint32',
      },
      {
        indexed: false,
        internalType: 'uint32',
        name: 'activatedAt',
        type: 'uint32',
      },
    ],
    name: 'DistributionRootSubmitted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint16',
        name: 'oldGlobalCommissionBips',
        type: 'uint16',
      },
      {
        indexed: false,
        internalType: 'uint16',
        name: 'newGlobalCommissionBips',
        type: 'uint16',
      },
    ],
    name: 'GlobalCommissionBipsSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IPauserRegistry',
        name: 'pauserRegistry',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract IPauserRegistry',
        name: 'newPauserRegistry',
        type: 'address',
      },
    ],
    name: 'PauserRegistrySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'root',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'earner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'claimer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'claimedAmount',
        type: 'uint256',
      },
    ],
    name: 'RewardsClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardsForAllSubmitter',
        type: 'address',
      },
      { indexed: true, internalType: 'bool', name: 'oldValue', type: 'bool' },
      { indexed: true, internalType: 'bool', name: 'newValue', type: 'bool' },
    ],
    name: 'RewardsForAllSubmitterSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'submitter',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'submissionNonce',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'rewardsSubmissionHash',
        type: 'bytes32',
      },
      {
        components: [
          {
            components: [
              {
                internalType: 'contract IStrategy',
                name: 'strategy',
                type: 'address',
              },
              { internalType: 'uint96', name: 'multiplier', type: 'uint96' },
            ],
            internalType: 'struct IRewardsCoordinator.StrategyAndMultiplier[]',
            name: 'strategiesAndMultipliers',
            type: 'tuple[]',
          },
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint32', name: 'startTimestamp', type: 'uint32' },
          { internalType: 'uint32', name: 'duration', type: 'uint32' },
        ],
        indexed: false,
        internalType: 'struct IRewardsCoordinator.RewardsSubmission',
        name: 'rewardsSubmission',
        type: 'tuple',
      },
    ],
    name: 'RewardsSubmissionForAllCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'oldRewardsUpdater',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newRewardsUpdater',
        type: 'address',
      },
    ],
    name: 'RewardsUpdaterSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    inputs: [],
    name: 'CALCULATION_INTERVAL_SECONDS',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'GENESIS_REWARDS_TIMESTAMP',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_FUTURE_LENGTH',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_RETROACTIVE_LENGTH',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_REWARDS_DURATION',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'activationDelay',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'beaconChainETHStrategy',
    outputs: [{ internalType: 'contract IStrategy', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'earner', type: 'address' },
          { internalType: 'bytes32', name: 'earnerTokenRoot', type: 'bytes32' },
        ],
        internalType: 'struct IRewardsCoordinator.EarnerTreeMerkleLeaf',
        name: 'leaf',
        type: 'tuple',
      },
    ],
    name: 'calculateEarnerLeafHash',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'cumulativeEarnings',
            type: 'uint256',
          },
        ],
        internalType: 'struct IRewardsCoordinator.TokenTreeMerkleLeaf',
        name: 'leaf',
        type: 'tuple',
      },
    ],
    name: 'calculateTokenLeafHash',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint32', name: 'rootIndex', type: 'uint32' },
          { internalType: 'uint32', name: 'earnerIndex', type: 'uint32' },
          { internalType: 'bytes', name: 'earnerTreeProof', type: 'bytes' },
          {
            components: [
              { internalType: 'address', name: 'earner', type: 'address' },
              {
                internalType: 'bytes32',
                name: 'earnerTokenRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct IRewardsCoordinator.EarnerTreeMerkleLeaf',
            name: 'earnerLeaf',
            type: 'tuple',
          },
          { internalType: 'uint32[]', name: 'tokenIndices', type: 'uint32[]' },
          { internalType: 'bytes[]', name: 'tokenTreeProofs', type: 'bytes[]' },
          {
            components: [
              {
                internalType: 'contract IERC20',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'cumulativeEarnings',
                type: 'uint256',
              },
            ],
            internalType: 'struct IRewardsCoordinator.TokenTreeMerkleLeaf[]',
            name: 'tokenLeaves',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IRewardsCoordinator.RewardsMerkleClaim',
        name: 'claim',
        type: 'tuple',
      },
    ],
    name: 'checkClaim',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'claimerFor',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: 'contract IStrategy',
                name: 'strategy',
                type: 'address',
              },
              { internalType: 'uint96', name: 'multiplier', type: 'uint96' },
            ],
            internalType: 'struct IRewardsCoordinator.StrategyAndMultiplier[]',
            name: 'strategiesAndMultipliers',
            type: 'tuple[]',
          },
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint32', name: 'startTimestamp', type: 'uint32' },
          { internalType: 'uint32', name: 'duration', type: 'uint32' },
        ],
        internalType: 'struct IRewardsCoordinator.RewardsSubmission[]',
        name: 'rewardsSubmissions',
        type: 'tuple[]',
      },
    ],
    name: 'createAVSRewardsSubmission',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: 'contract IStrategy',
                name: 'strategy',
                type: 'address',
              },
              { internalType: 'uint96', name: 'multiplier', type: 'uint96' },
            ],
            internalType: 'struct IRewardsCoordinator.StrategyAndMultiplier[]',
            name: 'strategiesAndMultipliers',
            type: 'tuple[]',
          },
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint32', name: 'startTimestamp', type: 'uint32' },
          { internalType: 'uint32', name: 'duration', type: 'uint32' },
        ],
        internalType: 'struct IRewardsCoordinator.RewardsSubmission[]',
        name: 'rewardsSubmissions',
        type: 'tuple[]',
      },
    ],
    name: 'createRewardsForAllSubmission',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'contract IERC20', name: '', type: 'address' },
    ],
    name: 'cumulativeClaimed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currRewardsCalculationEndTimestamp',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'delegationManager',
    outputs: [
      {
        internalType: 'contract IDelegationManager',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'domainSeparator',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentDistributionRoot',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'root', type: 'bytes32' },
          {
            internalType: 'uint32',
            name: 'rewardsCalculationEndTimestamp',
            type: 'uint32',
          },
          { internalType: 'uint32', name: 'activatedAt', type: 'uint32' },
        ],
        internalType: 'struct IRewardsCoordinator.DistributionRoot',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
    name: 'getDistributionRootAtIndex',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'root', type: 'bytes32' },
          {
            internalType: 'uint32',
            name: 'rewardsCalculationEndTimestamp',
            type: 'uint32',
          },
          { internalType: 'uint32', name: 'activatedAt', type: 'uint32' },
        ],
        internalType: 'struct IRewardsCoordinator.DistributionRoot',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getDistributionRootsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'rootHash', type: 'bytes32' }],
    name: 'getRootIndexFromHash',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'globalOperatorCommissionBips',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'initialOwner', type: 'address' },
      {
        internalType: 'contract IPauserRegistry',
        name: '_pauserRegistry',
        type: 'address',
      },
      { internalType: 'uint256', name: 'initialPausedStatus', type: 'uint256' },
      { internalType: 'address', name: '_rewardsUpdater', type: 'address' },
      { internalType: 'uint32', name: '_activationDelay', type: 'uint32' },
      { internalType: 'uint16', name: '_globalCommissionBips', type: 'uint16' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'bytes32', name: '', type: 'bytes32' },
    ],
    name: 'isAVSRewardsSubmissionHash',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'isRewardsForAllSubmitter',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'bytes32', name: '', type: 'bytes32' },
    ],
    name: 'isRewardsSubmissionForAllHash',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'operator', type: 'address' },
      { internalType: 'address', name: 'avs', type: 'address' },
    ],
    name: 'operatorCommissionBips',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newPausedStatus', type: 'uint256' }],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauseAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint8', name: 'index', type: 'uint8' }],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauserRegistry',
    outputs: [{ internalType: 'contract IPauserRegistry', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint32', name: 'rootIndex', type: 'uint32' },
          { internalType: 'uint32', name: 'earnerIndex', type: 'uint32' },
          { internalType: 'bytes', name: 'earnerTreeProof', type: 'bytes' },
          {
            components: [
              { internalType: 'address', name: 'earner', type: 'address' },
              {
                internalType: 'bytes32',
                name: 'earnerTokenRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct IRewardsCoordinator.EarnerTreeMerkleLeaf',
            name: 'earnerLeaf',
            type: 'tuple',
          },
          { internalType: 'uint32[]', name: 'tokenIndices', type: 'uint32[]' },
          { internalType: 'bytes[]', name: 'tokenTreeProofs', type: 'bytes[]' },
          {
            components: [
              {
                internalType: 'contract IERC20',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'cumulativeEarnings',
                type: 'uint256',
              },
            ],
            internalType: 'struct IRewardsCoordinator.TokenTreeMerkleLeaf[]',
            name: 'tokenLeaves',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IRewardsCoordinator.RewardsMerkleClaim',
        name: 'claim',
        type: 'tuple',
      },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'processClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardsUpdater',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint32', name: '_activationDelay', type: 'uint32' }],
    name: 'setActivationDelay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'claimer', type: 'address' }],
    name: 'setClaimerFor',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint16', name: '_globalCommissionBips', type: 'uint16' }],
    name: 'setGlobalOperatorCommission',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IPauserRegistry',
        name: 'newPauserRegistry',
        type: 'address',
      },
    ],
    name: 'setPauserRegistry',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_submitter', type: 'address' },
      { internalType: 'bool', name: '_newValue', type: 'bool' },
    ],
    name: 'setRewardsForAllSubmitter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_rewardsUpdater', type: 'address' }],
    name: 'setRewardsUpdater',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'strategyManager',
    outputs: [{ internalType: 'contract IStrategyManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'submissionNonce',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'root', type: 'bytes32' },
      {
        internalType: 'uint32',
        name: 'rewardsCalculationEndTimestamp',
        type: 'uint32',
      },
    ],
    name: 'submitRoot',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newPausedStatus', type: 'uint256' }],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;
