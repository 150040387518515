/* eslint-disable turbo/no-undeclared-env-vars */
import {
  mainnetEthereumConfig,
  preprodHoleskyConfig,
  testnetHoleskyConfig,
} from '@layr-labs/eigen-kit/configs';

import type { config } from '@layr-labs/eigen-kit/types';

let stakeConfig: config;

switch (process.env.NEXT_PUBLIC_CONTRACT_ENV) {
  case 'mainnet-ethereum':
    stakeConfig = mainnetEthereumConfig;
    break;
  case 'testnet-holesky':
    stakeConfig = testnetHoleskyConfig;
    break;
  case 'preprod-holesky':
    stakeConfig = preprodHoleskyConfig;
    break;
  default:
    stakeConfig = testnetHoleskyConfig;
}

export { stakeConfig };
