export default [
  {
    inputs: [
      {
        internalType: 'contract IETHPOSDeposit',
        name: '_ethPOS',
        type: 'address',
      },
      {
        internalType: 'contract IDelayedWithdrawalRouter',
        name: '_delayedWithdrawalRouter',
        type: 'address',
      },
      {
        internalType: 'contract IEigenPodManager',
        name: '_eigenPodManager',
        type: 'address',
      },
      {
        internalType: 'uint64',
        name: '_MAX_VALIDATOR_BALANCE_GWEI',
        type: 'uint64',
      },
      {
        internalType: 'uint64',
        name: '_RESTAKED_BALANCE_OFFSET_GWEI',
        type: 'uint64',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'pubkey',
        type: 'bytes',
      },
    ],
    name: 'EigenPodStaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint40',
        name: 'validatorIndex',
        type: 'uint40',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'withdrawalTimestamp',
        type: 'uint64',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'withdrawalAmountGwei',
        type: 'uint64',
      },
    ],
    name: 'FullWithdrawalRedeemed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountReceived',
        type: 'uint256',
      },
    ],
    name: 'NonBeaconChainETHReceived',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountWithdrawn',
        type: 'uint256',
      },
    ],
    name: 'NonBeaconChainETHWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint40',
        name: 'validatorIndex',
        type: 'uint40',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'withdrawalTimestamp',
        type: 'uint64',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'partialWithdrawalAmountGwei',
        type: 'uint64',
      },
    ],
    name: 'PartialWithdrawalRedeemed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'RestakedBeaconChainETHWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'podOwner',
        type: 'address',
      },
    ],
    name: 'RestakingActivated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint40',
        name: 'validatorIndex',
        type: 'uint40',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'balanceTimestamp',
        type: 'uint64',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'newValidatorBalanceGwei',
        type: 'uint64',
      },
    ],
    name: 'ValidatorBalanceUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint40',
        name: 'validatorIndex',
        type: 'uint40',
      },
    ],
    name: 'ValidatorRestaked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'MAX_VALIDATOR_BALANCE_GWEI',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'RESTAKED_BALANCE_OFFSET_GWEI',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'activateRestaking',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amountWei', type: 'uint256' }],
    name: 'decrementWithdrawableRestakedExecutionLayerGwei',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'delayedWithdrawalRouter',
    outputs: [
      {
        internalType: 'contract IDelayedWithdrawalRouter',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'eigenPodManager',
    outputs: [
      {
        internalType: 'contract IEigenPodManager',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ethPOS',
    outputs: [
      {
        internalType: 'contract IETHPOSDeposit',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'hasRestaked',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amountWei', type: 'uint256' }],
    name: 'incrementWithdrawableRestakedExecutionLayerGwei',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_podOwner', type: 'address' }],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'mostRecentWithdrawalTimestamp',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nonBeaconChainETHBalanceWei',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'podOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '', type: 'bytes32' },
      { internalType: 'uint64', name: '', type: 'uint64' },
    ],
    name: 'provenWithdrawal',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20[]',
        name: 'tokenList',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'amountsToWithdraw',
        type: 'uint256[]',
      },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'recoverTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes', name: 'pubkey', type: 'bytes' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
      {
        internalType: 'bytes32',
        name: 'depositDataRoot',
        type: 'bytes32',
      },
    ],
    name: 'stake',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'validatorPubkeyHash',
        type: 'bytes32',
      },
    ],
    name: 'validatorPubkeyHashToInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint64',
            name: 'validatorIndex',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'restakedBalanceGwei',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'mostRecentBalanceUpdateTimestamp',
            type: 'uint64',
          },
          {
            internalType: 'enum IEigenPod.VALIDATOR_STATUS',
            name: 'status',
            type: 'uint8',
          },
        ],
        internalType: 'struct IEigenPod.ValidatorInfo',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'pubkeyHash', type: 'bytes32' }],
    name: 'validatorStatus',
    outputs: [
      {
        internalType: 'enum IEigenPod.VALIDATOR_STATUS',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint64', name: 'oracleTimestamp', type: 'uint64' },
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'beaconStateRoot',
            type: 'bytes32',
          },
          { internalType: 'bytes', name: 'proof', type: 'bytes' },
        ],
        internalType: 'struct BeaconChainProofs.StateRootProof',
        name: 'stateRootProof',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'bytes',
            name: 'withdrawalProof',
            type: 'bytes',
          },
          { internalType: 'bytes', name: 'slotProof', type: 'bytes' },
          {
            internalType: 'bytes',
            name: 'executionPayloadProof',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'timestampProof',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'historicalSummaryBlockRootProof',
            type: 'bytes',
          },
          {
            internalType: 'uint64',
            name: 'blockRootIndex',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'historicalSummaryIndex',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'withdrawalIndex',
            type: 'uint64',
          },
          { internalType: 'bytes32', name: 'blockRoot', type: 'bytes32' },
          { internalType: 'bytes32', name: 'slotRoot', type: 'bytes32' },
          {
            internalType: 'bytes32',
            name: 'timestampRoot',
            type: 'bytes32',
          },
          {
            internalType: 'bytes32',
            name: 'executionPayloadRoot',
            type: 'bytes32',
          },
        ],
        internalType: 'struct BeaconChainProofs.WithdrawalProof[]',
        name: 'withdrawalProofs',
        type: 'tuple[]',
      },
      {
        internalType: 'bytes[]',
        name: 'validatorFieldsProofs',
        type: 'bytes[]',
      },
      {
        internalType: 'bytes32[][]',
        name: 'validatorFields',
        type: 'bytes32[][]',
      },
      {
        internalType: 'bytes32[][]',
        name: 'withdrawalFields',
        type: 'bytes32[][]',
      },
    ],
    name: 'verifyAndProcessWithdrawals',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint64', name: 'oracleTimestamp', type: 'uint64' },
      { internalType: 'uint40', name: 'validatorIndex', type: 'uint40' },
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'beaconStateRoot',
            type: 'bytes32',
          },
          { internalType: 'bytes', name: 'proof', type: 'bytes' },
        ],
        internalType: 'struct BeaconChainProofs.StateRootProof',
        name: 'stateRootProof',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'bytes',
            name: 'validatorBalanceProof',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'validatorFieldsProof',
            type: 'bytes',
          },
          {
            internalType: 'bytes32',
            name: 'balanceRoot',
            type: 'bytes32',
          },
        ],
        internalType: 'struct BeaconChainProofs.BalanceUpdateProof',
        name: 'balanceUpdateProof',
        type: 'tuple',
      },
      {
        internalType: 'bytes32[]',
        name: 'validatorFields',
        type: 'bytes32[]',
      },
    ],
    name: 'verifyBalanceUpdate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint64', name: 'oracleTimestamp', type: 'uint64' },
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'beaconStateRoot',
            type: 'bytes32',
          },
          { internalType: 'bytes', name: 'proof', type: 'bytes' },
        ],
        internalType: 'struct BeaconChainProofs.StateRootProof',
        name: 'stateRootProof',
        type: 'tuple',
      },
      {
        internalType: 'uint40[]',
        name: 'validatorIndices',
        type: 'uint40[]',
      },
      {
        internalType: 'bytes[]',
        name: 'validatorFieldsProofs',
        type: 'bytes[]',
      },
      {
        internalType: 'bytes32[][]',
        name: 'validatorFields',
        type: 'bytes32[][]',
      },
    ],
    name: 'verifyWithdrawalCredentials',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawBeforeRestaking',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      {
        internalType: 'uint256',
        name: 'amountToWithdraw',
        type: 'uint256',
      },
    ],
    name: 'withdrawNonBeaconChainETHBalanceWei',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amountWei', type: 'uint256' },
    ],
    name: 'withdrawRestakedBeaconChainETH',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawableRestakedExecutionLayerGwei',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;
