import { config } from '../types';
import appConfig from './app-config/config.json';

export { appConfig };

type AppConfig = {
  mainnetEthereum: Omit<config, 'icon'>;
  testnetHolesky: Omit<config, 'icon'>;
  preprodHolesky: Omit<config, 'icon'>;
};
const typedConfig = Object.assign(
  {},
  ...Object.entries(appConfig as unknown as AppConfig).map(([network, c]) => ({
    [network as keyof AppConfig]: {
      ...c,
      rewardsTokenList: c.rewardsTokenList || [],
      rewardsCoordinatorAddress: c.rewardsCoordinatorAddress || undefined,
    } as config,
  })),
) as AppConfig;

const icons: Record<string, string> = {
  reth: 'tokenIcon/rETH.svg',
  steth: 'tokenIcon/stETH.svg',
  cbeth: 'tokenIcon/cbETH.svg',
  oseth: 'tokenIcon/osETH.webp',
  sweth: 'tokenIcon/swETH.webp',
  oeth: 'tokenIcon/oETH.webp',
  wbeth: 'tokenIcon/wbETH.webp',
  ankreth: 'tokenIcon/ankrETH.webp',
  ethx: 'tokenIcon/staderx.webp',
  sfrxeth: 'tokenIcon/sfrxeth.webp',
  lseth: 'tokenIcon/lsETH.webp',
  meth: 'tokenIcon/mETH.png',
  eth: 'tokenIcon/ETH.svg',
  weth: 'tokenIcon/WETH.svg',
  wethq2: 'tokenIcon/WETH.svg',
  eigen: 'tokenIcon/EIGEN.webp',
  einu: 'tokenIcon/EINU.webp',
  realt: 'tokenIcon/reALT.svg',
  eo: 'tokenIcon/EO.svg',
  altinu: 'tokenIcon/ALTINU.png',
  trouter: 'tokenIcon/TRouter.png',
  rez: 'tokenIcon/rez.webp',
  unibtc: 'tokenIcon/uniBTC.webp',
  tbtc: 'tokenIcon/tBTC.webp',
  arpa: 'tokenIcon/arpa.webp',
  swell: 'tokenIcon/swell.png',
  frax: 'tokenIcon/FRAX.png',
  sdai: 'tokenIcon/sDAI.png',
  usdt: 'tokenIcon/USDT.png',
  wbtc: 'tokenIcon/WBTC.png',
  dai: 'tokenIcon/DAI.png',
  eusd: 'tokenIcon/eUSD.png',
  alt: 'tokenIcon/ALT.png',
  usdc: 'tokenIcon/USDC.png',
  susde: 'tokenIcon/sUSDe.png',
  pepe: 'tokenIcon/PEPE.png',
  ausd: 'tokenIcon/AUSD.png',
  t: 'tokenIcon/T.webp',
  solvbtc: 'tokenIcon/SolvBTC.png',
  lbtc: 'tokenIcon/LBTC.png',
  fbtc: 'tokenIcon/fbtc.webp',
};

export const PROJECT_ID = 'be72ab7350f1efa18d91616a51bc037c';

export const mainnetEthereumConfig: config = {
  ...typedConfig.mainnetEthereum,
  stakingTokenList: typedConfig.mainnetEthereum.stakingTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  rewardsTokenList: typedConfig.mainnetEthereum.rewardsTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  nativeToken: {
    ...typedConfig.mainnetEthereum.nativeToken,
    icon: icons[typedConfig.mainnetEthereum.nativeToken.symbol.toLowerCase()],
  },
};

export const testnetHoleskyConfig: config = {
  ...typedConfig.testnetHolesky,
  stakingTokenList: typedConfig.testnetHolesky.stakingTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  rewardsTokenList: typedConfig.testnetHolesky.rewardsTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  nativeToken: {
    ...typedConfig.testnetHolesky.nativeToken,
    icon: icons[typedConfig.testnetHolesky.nativeToken.symbol.toLowerCase()],
  },
};

export const preprodHoleskyConfig: config = {
  ...typedConfig.preprodHolesky,
  stakingTokenList: typedConfig.preprodHolesky.stakingTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  rewardsTokenList: typedConfig.preprodHolesky.rewardsTokenList.map((token) => ({
    ...token,
    icon: icons[token.symbol.toLowerCase()],
  })),
  nativeToken: {
    ...typedConfig.preprodHolesky.nativeToken,
    icon: icons[typedConfig.preprodHolesky.nativeToken.symbol.toLowerCase()],
  },
};

export const ankrETHGoerli = {
  address: '0x2bBC91e1990F0dc5e5BAD04AaE000Ca97f56990f' as `0x${string}`,
  functionName: 'sharesToBonds',
  abi: [
    {
      name: 'sharesToBonds',
      type: 'function',
      stateMutability: 'view',
      inputs: [{ type: 'uint256', name: 'amount' }],
      outputs: [{ type: 'uint256' }],
    },
  ],
} as const;
