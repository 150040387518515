export default [
  {
    inputs: [
      {
        internalType: 'contract IEigenPodManager',
        name: '_eigenPodManager',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'podOwner',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'DelayedWithdrawalCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountClaimed',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'delayedWithdrawalsCompleted',
        type: 'uint256',
      },
    ],
    name: 'DelayedWithdrawalsClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IPauserRegistry',
        name: 'pauserRegistry',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract IPauserRegistry',
        name: 'newPauserRegistry',
        type: 'address',
      },
    ],
    name: 'PauserRegistrySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'previousValue',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newValue',
        type: 'uint256',
      },
    ],
    name: 'WithdrawalDelayBlocksSet',
    type: 'event',
  },
  {
    inputs: [],
    name: 'MAX_WITHDRAWAL_DELAY_BLOCKS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'canClaimDelayedWithdrawal',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'maxNumberOfDelayedWithdrawalsToClaim',
        type: 'uint256',
      },
    ],
    name: 'claimDelayedWithdrawals',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      {
        internalType: 'uint256',
        name: 'maxNumberOfDelayedWithdrawalsToClaim',
        type: 'uint256',
      },
    ],
    name: 'claimDelayedWithdrawals',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'podOwner', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'createDelayedWithdrawal',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'eigenPodManager',
    outputs: [
      {
        internalType: 'contract IEigenPodManager',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getClaimableUserDelayedWithdrawals',
    outputs: [
      {
        components: [
          { internalType: 'uint224', name: 'amount', type: 'uint224' },
          { internalType: 'uint32', name: 'blockCreated', type: 'uint32' },
        ],
        internalType: 'struct IDelayedWithdrawalRouter.DelayedWithdrawal[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getUserDelayedWithdrawals',
    outputs: [
      {
        components: [
          { internalType: 'uint224', name: 'amount', type: 'uint224' },
          { internalType: 'uint32', name: 'blockCreated', type: 'uint32' },
        ],
        internalType: 'struct IDelayedWithdrawalRouter.DelayedWithdrawal[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'initOwner', type: 'address' },
      {
        internalType: 'contract IPauserRegistry',
        name: '_pauserRegistry',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'initPausedStatus',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_withdrawalDelayBlocks',
        type: 'uint256',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauseAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint8', name: 'index', type: 'uint8' }],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauserRegistry',
    outputs: [
      {
        internalType: 'contract IPauserRegistry',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IPauserRegistry',
        name: 'newPauserRegistry',
        type: 'address',
      },
    ],
    name: 'setPauserRegistry',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newValue', type: 'uint256' }],
    name: 'setWithdrawalDelayBlocks',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newPausedStatus',
        type: 'uint256',
      },
    ],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'userDelayedWithdrawalByIndex',
    outputs: [
      {
        components: [
          { internalType: 'uint224', name: 'amount', type: 'uint224' },
          { internalType: 'uint32', name: 'blockCreated', type: 'uint32' },
        ],
        internalType: 'struct IDelayedWithdrawalRouter.DelayedWithdrawal',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'userWithdrawals',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'delayedWithdrawalsCompleted',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint224',
                name: 'amount',
                type: 'uint224',
              },
              {
                internalType: 'uint32',
                name: 'blockCreated',
                type: 'uint32',
              },
            ],
            internalType: 'struct IDelayedWithdrawalRouter.DelayedWithdrawal[]',
            name: 'delayedWithdrawals',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IDelayedWithdrawalRouter.UserDelayedWithdrawals',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'userWithdrawalsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawalDelayBlocks',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
