{
  "mainnetEthereum": {
    "eigenPodManagerAddress": "0x91E677b07F7AF907ec9a428aafA9fc14a0d3A338",
    "strategyManagerAddress": "0x858646372CC42E1A627fcE94aa7A7033e7CF075A",
    "delayedWithdrawalRouterAddress": "0x7Fe7E9CC0F274d2435AD5d56D5fa73E47F6A23D8",
    "delegationManagerAddress": "0x39053D51B77DC0d36036Fc1fCc8Cb819df8Ef37A",
    "eigendaServiceManagerAddress": "0x870679E138bCdf293b7Ff14dD44b70FC97e12fc0",
    "rewardsCoordinatorAddress": "0x7750d328b314EfFa365A0402CcfD489B80B0adda",
    "stakingTokenList": [
      {
        "name": "EIGEN",
        "symbol": "EIGEN",
        "decimals": 18,
        "apiID": "eigenlayer",
        "group": "EIGEN",
        "rebasing": false,
        "address": "0xec53bf9167f50cdeb3ae105f56099aaab9061f83",
        "strategyAddress": "0xaCB55C530Acdb2849e6d4f36992Cd8c9D50ED8F7",
        "usedForPoints": false,
        "about": "EIGEN is the universal intersubjective work token that significantly expands the types of credible commitments that any staker can make with cryptoeconomic security. This massively expands the surface area of digital tasks that can be secured on Ethereum beyond just tasks of type \"objectively attributable\" that was offered by simple restaking. Now, oracles, DA, off-chain VM such as AI or gaming engines, censorship-resistance module, etc., can be secured via EIGEN. The intersubjective nature of EIGEN enables raising challenges against malicious EIGEN stakers/operators that can trigger intersubjective forking of the token. Please see the whitepaper for detailed overview of the risks associated with such intersubjective forking."
      },
      {
        "name": "Restaked ALT",
        "symbol": "reALT",
        "decimals": 18,
        "apiID": null,
        "group": "reALT",
        "rebasing": false,
        "address": "0xF96798F49936EfB1a56F99Ceae924b6B8359afFb",
        "strategyAddress": "0x6075546538c3eFbD607ea6aFC24149fCcFb2edF4",
        "usedForPoints": false,
        "about": "reALT, restaked ALT token, is an auto-compounding ERC20 share token that captures the ALT staking contribution of a holder and accumulates the compounding rewards earned."
      },
      {
        "name": "Rocket Pool Ether",
        "symbol": "rETH",
        "decimals": 18,
        "apiID": "rocket-pool-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0xae78736Cd615f374D3085123A210448E74Fc6393",
        "strategyAddress": "0x1BeE69b7dFFfA4E2d53C2a2Df135C388AD25dCD2",
        "usedForPoints": true,
        "about": "Rocket Pool is a decentralized staking pool protocol for Ethereum. The Rocket Pool ETH (rETH) token is a liquid staking token within the Rocket Pool protocol. The rETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases. rETH had 2224 node operators running 6 validators each on average, as of Q1 2023. To restake rETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/rocket_pool",
        "website": "https://rocketpool.net"
      },
      {
        "name": "Lido Staked Ether",
        "symbol": "stETH",
        "decimals": 18,
        "apiID": "staked-ether",
        "group": "lst",
        "rebasing": true,
        "address": "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
        "strategyAddress": "0x93c4b944D05dfe6df7645A86cd2206016c51564D",
        "usedForPoints": true,
        "about": "stETH is a token that represents staked Ether in the Lido staking pool protocol. stETH tokens are issued 1:1 to staked Ether. When token rewards are earned from the Ethereum Proof-of-Stake consensus mechanism, stETH token balances increase for each holder. stETH had 30 node operators running 5885 validators each on average, as of Q1 2023. To restake stETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/LidoFinance",
        "website": "https://lido.fi/"
      },
      {
        "name": "Coinbase Staked Ether",
        "symbol": "cbETH",
        "decimals": 18,
        "apiID": "coinbase-wrapped-staked-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
        "strategyAddress": "0x54945180dB7943c0ed0FEE7EdaB2Bd24620256bc",
        "usedForPoints": true,
        "about": "Coinbase Wrapped Staked ETH (cbETH) is an ERC20 utility token, which is a liquid representation of their staked ETH on Coinbase. The number of cbETH units you receive upon wrapping is based on the ETH to cbETH conversion rate. The conversion rate will change over time as rewards are earned on the underlying staked ETH. To restake cbETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/coinbase",
        "website": "https://www.coinbase.com/cbeth"
      },
      {
        "name": "StakeWise Staked Ether",
        "symbol": "osETH",
        "decimals": 18,
        "apiID": "stakewise-v3-oseth",
        "group": "lst",
        "rebasing": false,
        "address": "0xf1C9acDc66974dFB6dEcB12aA385b9cD01190E38",
        "strategyAddress": "0x57ba429517c3473B6d34CA9aCd56c0e735b94c02",
        "usedForPoints": true,
        "about": "osETH is the slashing-protected liquid staking token representing capital staked on StakeWise, a staking marketplace that allows anyone to stake on their own terms. The osETH conversion rate changes over time based on the underlying staked ETH rewards and penalties. To restake osETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/stakewise_io",
        "website": "https://stakewise.io"
      },
      {
        "name": "Swell Staked Ether",
        "symbol": "swETH",
        "decimals": 18,
        "apiID": "sweth",
        "group": "lst",
        "rebasing": false,
        "address": "0xf951e335afb289353dc249e82926178eac7ded78",
        "strategyAddress": "0x0Fe4F44beE93503346A3Ac9EE5A26b130a5796d6",
        "usedForPoints": true,
        "about": "Swell Ether (swETH) is an Ethereum liquid staking token (LST) by Swell protocol. It represents ETH staked on Ethereum, with the underlying ETH being verified by Chainlink Proof of Reserves. swETH is a repricing token that appreciates over time as staking rewards are earned.",
        "twitter": "https://twitter.com/swellnetworkio",
        "website": "https://swellnetwork.io"
      },
      {
        "name": "Origin Staked Ether",
        "symbol": "oETH",
        "decimals": 18,
        "apiID": "origin-ether",
        "group": "lst",
        "rebasing": true,
        "address": "0x856c4Efb76C1D1AE02e20CEB03A2A6a08b0b8dC3",
        "strategyAddress": "0xa4C637e0F704745D182e4D38cAb7E7485321d059",
        "usedForPoints": true,
        "about": "Origin Ether (OETH) is a liquid staking yield aggregator on Ethereum. OETH combines the yield-generating functions of the largest LSTs into one single token. OETH is permissionless, fully collateralized, self-custodial, passive, and fully transparent. There are no lock-ups or conditions required to earn yield, yield is distributed daily and automatically directly into users' wallets. To restake OETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/originprotocol",
        "website": "https://oeth.com"
      },
      {
        "name": "Binance Staked Ether",
        "symbol": "wBETH",
        "decimals": 18,
        "apiID": "wrapped-beacon-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0xa2e3356610840701bdf5611a53974510ae27e2e1",
        "strategyAddress": "0x7CA911E83dabf90C90dD3De5411a10F1A6112184",
        "usedForPoints": true,
        "about": "Wrapped Beacon ETH (WBETH) is a value-accruing liquid staking token, where 1 WBETH represents 1 staked ETH, along with the staking rewards accrued on ETH Staking. You can use WBETH to interact with products on Binance and DeFi protocols, while still receiving the corresponding ETH Staking rewards accrued. You can stake ETH to WBETH or redeem your WBETH to ETH on the Binance ETH Staking page at zero fees.",
        "twitter": "https://twitter.com/binance",
        "website": "https://www.binance.com/en/ethereum-staking"
      },
      {
        "name": "Ankr Staked Ether",
        "symbol": "ankrETH",
        "decimals": 18,
        "apiID": "ankreth",
        "group": "lst",
        "rebasing": false,
        "address": "0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
        "strategyAddress": "0x13760F50a9d7377e4F20CB8CF9e4c26586c658ff",
        "usedForPoints": true,
        "about": "Ankr Staking is a pioneer in liquid staking, having launched the first ETH Liquid Staking Token (LST) in December 2020. The Ankr Staked ETH (ankrETH) token is a liquid staking token within Ankr Staking Protocol. The ankrETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases.",
        "twitter": "https://twitter.com/ankr",
        "website": "https://www.ankr.com/staking-crypto"
      },
      {
        "name": "Stader Staked Ether",
        "symbol": "ETHx",
        "decimals": 18,
        "apiID": "stader-ethx",
        "group": "lst",
        "rebasing": false,
        "address": "0xA35b1B31Ce002FBF2058D22F30f95D405200A15b",
        "strategyAddress": "0x9d7eD45EE2E8FC5482fa2428f15C971e6369011d",
        "usedForPoints": true,
        "about": "Stader's ETHx is a liquid staking token on Ethereum, offering users the ability to earn staking rewards. The ETHx token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. It employs a multi-pool architecture, dividing staked Ethereum between Permissionless and Permissioned node operator pools, ensuring scalability and decentralization.",
        "twitter": "https://twitter.com/staderlabs_eth",
        "website": "https://staderlabs.com/eth"
      },
      {
        "name": "Staked Frax Ether",
        "symbol": "sfrxETH",
        "decimals": 18,
        "apiID": "staked-frax-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xac3E018457B222d93114458476f3E3416Abbe38F",
        "strategyAddress": "0x8CA7A5d6f3acd3A7A8bC468a8CD0FB14B6BD28b6",
        "usedForPoints": true,
        "about": "Frax Ether is a decentralized liquid staking derivative designed to uniquely leverage the Frax Finance ecosystem. It is issued and created by the Frax Protocol and has a unique two token system with all validator income going to sfrxETH token holders. The sfrxETH token slowly increases in price over time as a function of validator income of the protocol and is always redeemable for the underlying ETH that backs it.",
        "twitter": "https://twitter.com/fraxfinance",
        "website": "https://frax.finance"
      },
      {
        "name": "Liquid Staked Ether",
        "symbol": "lsETH",
        "decimals": 18,
        "apiID": "liquid-staked-ethereum",
        "group": "lst",
        "rebasing": false,
        "address": "0x8c1bed5b9a0928467c9b1341da1d7bd5e10b6549",
        "strategyAddress": "0xAe60d8180437b5C34bB956822ac2710972584473",
        "usedForPoints": true,
        "about": "LsETH is the LST of Liquid Collective, a decentralized liquid staking protocol. Each LsETH token represents your ownership of staked ETH plus network and MEV rewards accrued. Supported across a broad ecosystem of leading platforms and custodians, LsETH features automatic reward restaking, enterprise-grade security, Node Operator performance agreements, and built-in slashing coverage.",
        "twitter": "https://twitter.com/liquid_col",
        "website": "https://liquidcollective.io"
      },
      {
        "name": "Mantle Staked Ether",
        "symbol": "mETH",
        "decimals": 18,
        "apiID": "mantle-staked-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa",
        "strategyAddress": "0x298aFB19A105D59E74658C4C334Ff360BadE6dd2",
        "usedForPoints": true,
        "about": "Mantle LSP is a permissionless and non-custodial ETH liquid staking protocol deployed on Ethereum L1 and governed by Mantle. Mantle LSP combines simple and modern design with robust risk management, and leverages extensive Mantle Ecosystem resources to deliver a highly rewarding experience and supercharges Mantle’s status as a yield powerhouse. Mantle Staked Ether ($mETH) serves as the value-accumulating receipt token of Mantle LSP.",
        "twitter": "https://twitter.com/0xMantle",
        "website": "https://www.mantle.xyz/meth"
      },
      {
        "name": "Renzo",
        "symbol": "REZ",
        "decimals": 18,
        "apiID": "renzo",
        "group": "other",
        "rebasing": false,
        "address": "0x3b50805453023a91a8bf641e279401a0b23fa6f9",
        "strategyAddress": "0xc9ad499c11677ea3815984295dd73c0858757b8e",
        "usedForPoints": true,
        "about": "Renzo is a Liquid Restaking Token (LRT) and Strategy Manager for EigenLayer. It is the interface to the EigenLayer ecosystem securing Actively Validated Services (AVSs) and offering a higher yield than ETH staking. REZ is the Governance Token for the Renzo Protocol which is backed by Liquid Restaking Token ezETH.",
        "twitter": "https://x.com/RenzoProtocol",
        "website": "https://www.renzoprotocol.com/"
      },
      {
        "name": "Universal BTC",
        "symbol": "uniBTC",
        "decimals": 8,
        "apiID": "universal-btc",
        "group": "other",
        "rebasing": false,
        "address": "0x004E9C3EF86bc1ca1f0bB5C7662861Ee93350568",
        "strategyAddress": "0x505241696ab63faec03ed7893246de52eb1a8cff",
        "usedForPoints": true,
        "about": "Bedrock is the first multi-asset liquid restaking protocol, backed by the best investors, including OKX Ventures and Babylon cofounder. As a liquid staking pioneer, Bedrock brings high yield and institutional-grade security to the community through uniBTC built on Babylon, uniETH built on Eigenlayer, and uniIOTX build on IoTeX.  We recently secured the largest delegation of wBTC during Bablyon's mainnet launch (double the next delegation). And have successfully run strong campaigns on many networks including on Arbitrum with $15m+ TVL growth, Optimism with almost $5m TVL growth, and numerous others coming in the next few weeks.",
        "twitter": "https://x.com/Bedrock_DeFi",
        "website": "https://www.bedrock.technology/"
      },
      {
        "name": "Threshold Bitcoin",
        "symbol": "tBTC",
        "decimals": 18,
        "apiID": "tbtc",
        "group": "other",
        "rebasing": false,
        "address": "0x18084fba666a33d37592fa2633fd49a74dd93a88",
        "strategyAddress": "0x1fc0db099e3452b6c20027578ca57722815df98f",
        "usedForPoints": true,
        "about": "tBTC is a decentralized wrapped BTC that features permissionless mint and redemption.",
        "twitter": "https://x.com/TheTNetwork",
        "website": "https://threshold.network"
      },
      {
        "name": "ARPA Network",
        "symbol": "ARPA",
        "decimals": 18,
        "apiID": "arpa",
        "group": "other",
        "rebasing": false,
        "address": "0xba50933c268f567bdc86e1ac131be072c6b0b71a",
        "strategyAddress": "0x66ea956907f7ed2fd816106f2f4d8c384c6d4f92",
        "about": "ARPA Network serves as the infrastructure of verifiable random number, secure wallet, decentralized custody etc, secured by ARPA Native Staking and Eigen Layer",
        "twitter": "https://x.com/arpaofficial",
        "website": "https://www.arpanetwork.io/"
      },
      {
        "name": "Swell Network",
        "symbol": "SWELL",
        "decimals": 18,
        "apiID": "swell-network",
        "group": "other",
        "rebasing": false,
        "address": "0x0a6e7ba5042b38349e437ec6db6214aec7b35676",
        "strategyAddress": "0xb8312b0b13dc1925b6aa2debb432b4c3b93b1dab",
        "about": "Swell is both an LST and LRT provider. We are the DAO that created swETH, one of the biggest LSTs on Eigen and rswETH one of the LRTs. We see SWELL as being a critical piece of security for seucring Swell L2, the L2 for restaking that we are building ",
        "twitter": "https://x.com/swellnetworkio",
        "website": "https://www.swellnetwork.io/"
      },
      {
        "name": "Frax",
        "symbol": "FRAX",
        "decimals": 18,
        "apiID": "frax",
        "group": "other",
        "rebasing": false,
        "address": "0x853d955acef822db058eb8505911ed77f175b99e",
        "strategyAddress": "0x01f3be8090c3f3a273c9cd598d8cfc94854a45dd",
        "usedForPoints": false,
        "about": "The first fractional-algorithmic stablecoin",
        "website": "https://frax.finance"
      },
      {
        "name": "Savings Dai",
        "symbol": "sDAI",
        "decimals": 18,
        "apiID": "savings-dai",
        "group": "other",
        "rebasing": false,
        "address": "0x83f20f44975d03b1b09e64809b757c47f942beea",
        "strategyAddress": "0x0858616b1a07b7c925ba7e8f9a33475887db3a36",
        "usedForPoints": false,
        "about": "",
        "website": "https://app.spark.fi/"
      },
      {
        "name": "Tether USD",
        "symbol": "USDT",
        "decimals": 6,
        "apiID": "tether",
        "group": "other",
        "rebasing": false,
        "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
        "strategyAddress": "0x2cb2201df702b01fec173fde6756496aebe546f4",
        "usedForPoints": false,
        "about": "Tether (USDT) is a cryptocurrency with a value meant to mirror the value of the U.S. dollar. The idea was to create a stable cryptocurrency that can be used like digital dollars. Coins that serve this purpose of being a stable dollar substitute are called \u201cstable coins.\u201d Tether is the most popular stable coin and even acts as a dollar replacement on many popular exchanges!",
        "website": "https://tether.to/"
      },
      {
        "name": "Wrapped BTC",
        "symbol": "WBTC",
        "decimals": 8,
        "apiID": "wrapped-bitcoin",
        "group": "other",
        "rebasing": false,
        "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        "strategyAddress": "0x6c6e8af98a49bbabcc17ca1dba6b95c5d58a2ccb",
        "usedForPoints": false,
        "about": "Wrapped Bitcoin is an ERC-20 token on the Ethereum blockchain that represents Bitcoin. Each WBTC is backed 1:1 with Bitcoin. Wrapped Bitcoin allows for Bitcoin transfers to be conducted quicker on the Ethereum blockchain and opens up the possibility for BTC to be used in the Ethereum ecosystem. Bitcoin is held in custody by the centralized custodian, BitGo. Bitcoin can be converted to Wrapped Bitcoin and vice versa easily.",
        "website": "https://www.wbtc.network/"
      },
      {
        "name": "Dai Stablecoin",
        "symbol": "DAI",
        "decimals": 18,
        "apiID": "dai",
        "group": "other",
        "rebasing": false,
        "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
        "strategyAddress": "0x71cb984bbcbae0e85c8d59db131246fa694e100d",
        "usedForPoints": false,
        "about": "MakerDAO has launched Multi-collateral DAI (MCD). This token refers to the new DAI that is collaterized by multiple assets.\r\n",
        "website": "https://makerdao.com/"
      },
      {
        "name": "Electronic Dollar",
        "symbol": "eUSD",
        "decimals": 18,
        "apiID": "electronic-usd",
        "group": "other",
        "rebasing": false,
        "address": "0xa0d69e286b938e21cbf7e51d71f6a4c8918f482f",
        "strategyAddress": "0x7251e23983643eab81e17e4f851c4e01ecafd882",
        "usedForPoints": false,
        "about": "",
        "website": "https://register.app/"
      },
      {
        "name": "AltLayer Token",
        "symbol": "ALT",
        "decimals": 18,
        "apiID": "altlayer",
        "group": "other",
        "rebasing": false,
        "address": "0x8457ca5040ad67fdebbcc8edce889a335bc0fbfb",
        "strategyAddress": "0x73a18a6304d05b495ecb161dbf1ab496461bbf2e",
        "usedForPoints": false,
        "about": "AltLayer is a decentralized protocol that facilitates the launch of native and restaked rollups with both optimistic and zk rollup stacks.",
        "website": "https://altlayer.io/"
      },
      {
        "name": "USD Coin",
        "symbol": "USDC",
        "decimals": 6,
        "apiID": "usd-coin",
        "group": "other",
        "rebasing": false,
        "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "strategyAddress": "0x752c665ae29bf52f3a162a944ae26882d03321e3",
        "usedForPoints": false,
        "about": "USDC is a fully collateralized US dollar stablecoin. USDC is the bridge between dollars and trading on cryptocurrency exchanges. The technology behind CENTRE makes it possible to exchange value between people, businesses and financial institutions just like email between mail services and texts between SMS providers. We believe by removing artificial economic borders, we can create a more inclusive global economy.",
        "website": "https://www.circle.com/en/usdc"
      },
      {
        "name": "Staked USDe",
        "symbol": "sUSDe",
        "decimals": 18,
        "apiID": "ethena-staked-usde",
        "group": "other",
        "rebasing": false,
        "address": "0x9d39a5de30e57443bff2a8307a4256c8797a3497",
        "strategyAddress": "0x8feb56c8802bda01f3cc1802d44f6cb469ac9b22",
        "usedForPoints": false,
        "about": "",
        "website": "https://app.ethena.fi/"
      },
      {
        "name": "Pepe",
        "symbol": "PEPE",
        "decimals": 18,
        "apiID": "pepe",
        "group": "other",
        "rebasing": false,
        "address": "0x6982508145454ce325ddbe47a25d4ec3d2311933",
        "strategyAddress": "0x99a05f4e3fa886a5104630e8a4b01159867ad9a1",
        "usedForPoints": false,
        "about": "What is the project about?\r\nPepe is a community based meme token surround the iconic meme Pepe the frog. Pepe aims to leverage the power of such an iconic meme to become the most memeable memecoin in existence. \r\n\r\nWhat makes your project unique?\r\nPepe is here to make memecoins great again. Ushering in a new paradigm for memecoins, Pepe represents the memecoin in it's purest simplicity. With zero taxes, liquidity locked forever, and contract immutable, Pepe is for the people, forever. Pepe is about culture, rallying together a community to have fun and enjoy memes, fueled purely by memetic power. \r\n\r\nHistory of your project.\r\nPepe was stealth launched on Friday, April 14th, 2023. \r\n\r\nWhat\u2019s next for your project?\r\nPepe will focus on developing a tight-knit community around the token and building resources to enrich the communities knowledge and success in crypto through a token gated group, newsletter, and more tools. \r\n\r\nWhat can your token be used for?\r\nPepe can be used to speculate on the power of memes, and does not pretend to be anything more. \r\n",
        "website": "https://www.pepe.vip/"
      },
      {
        "name": "AUSD",
        "symbol": "AUSD",
        "decimals": 6,
        "apiID": "agora-dollar",
        "group": "other",
        "rebasing": false,
        "address": "0x00000000efe302beaa2b3e6e1b18d08d69a9012a",
        "strategyAddress": "0xa33600c9b9c2a4585b5a2cecd5b10d0330cde9a2",
        "usedForPoints": false,
        "about": "AUSD, a digital dollar minted 1:1 with USD fiat. AUSD is designed to be a secure digital currency, utilizing one of the world\u2019s largest custodian banks to safeguard assets, a big 4 auditor and a top tier fund manager. AUSD enables users to participate in trading, lending and payments. It is the most cost-efficient stablecoin to transact with due to its gas-optimized smart contract, making it ideal for traders and payments.",
        "website": "https://agora.finance/"
      },
      {
        "name": "Wrapped Ether",
        "symbol": "WETH",
        "decimals": 18,
        "apiID": "weth",
        "group": "other",
        "rebasing": false,
        "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
        "strategyAddress": "0xa553a8198e0692b4393ac2f64bd2e42a2061c1c5",
        "usedForPoints": false,
        "about": "WETH is an ERC-20 token on Ethereum that represents 1 Ether (ETH). ETH is used to pay for transactions on the Ethereum blockchain. For example, swapping between cryptoassets on an Ethereum-based DEX will cost some small amount of ETH",
        "website": "https://weth.io/"
      },
      {
        "name": "Solv BTC",
        "symbol": "SolvBTC",
        "decimals": 18,
        "apiID": "solv-btc",
        "group": "other",
        "rebasing": false,
        "address": "0x7a56e1c57c7475ccf742a1832b028f0456652f97",
        "strategyAddress": "0x74acf103148e183c87ed24ae1d2b3b2c6654fc0a",
        "usedForPoints": false,
        "about": "SolvBTC addresses the significant gap in the Bitcoin ecosystem by providing a native yield solution. It fosters an inclusive and efficient BTCFi ecosystem, offering Bitcoin holders unprecedented opportunities for growth and participation.",
        "website": "https://app.solv.finance/solvbtc"
      },
      {
        "name": "Lombard Staked Bitcoin",
        "symbol": "LBTC",
        "decimals": 8,
        "apiID": "lombard-staked-btc",
        "group": "other",
        "rebasing": false,
        "address": "0x8236a87084f8b84306f72007f36f2618a5634494",
        "strategyAddress": "0xeb6cc669971d5310bea32291d5d2087474d710ec",
        "usedForPoints": false,
        "about": "Lombard unlocks Bitcoin’s potential as a dynamic financial tool by connecting it to the world of decentralized finance (DeFi). Its flagship product, LBTC, is the Universal Liquid Bitcoin Standard, which is yield-bearing, natively cross-chain, and backed 1:1 by BTC. LBTC empowers BTC holders to earn Babylon staking yields and actively engage in DeFi activities such as trading, borrowing, lending, yield farming, and more.",
        "website": "https://www.lombard.finance/"
      },
      {
        "name": "Fire BTC",
        "symbol": "FBTC",
        "decimals": 8,
        "apiID": "ignition-fbtc",
        "group": "other",
        "rebasing": false,
        "address": "0xC96dE26018A54D51c097160568752c4E3BD6C364",
        "strategyAddress": "0xAD855d1E5DBbffcBF3249209dA28BeB9a8259fFE",
        "usedForPoints": false,
        "about": "FBTC is an omnichain wrapped BTC project designed to address key issues with current market solutions, particularly in security and yield generation. By enhancing security through advanced custody solutions and creating new opportunities for BTC holders to earn yield on-chain, FBTC offers a practical and innovative alternative to existing wrapped Bitcoin products.",
        "website": "https://www.fbtc.com"
      }
    ],
    "nativeToken": {
      "name": "Natively Staked Ether",
      "symbol": "ETH",
      "decimals": 18,
      "apiID": "ethereum",
      "group": "native",
      "rebasing": false,
      "address": "0x0000000000000000000000000000000000000000",
      "strategyAddress": "0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0",
      "usedForPoints": true,
      "about": "Native Restaking means running one or more Ethereum beacon chain validators while connected to the EigenLayer protocol. In order to participate in Native Restaking, you must run at least one Ethereum validator and set its withdrawal address to your EigenPod. \n\nOnce your EigenPod is deployed and your validator(s) are running, the beacon chain will generate rewards for your validators and deposit them into your EigenPod. As these rewards accumulate in your pod, you can restake them to earn additional rewards through EigenLayer or you can withdraw them to your personal wallet."
    },
    "rewardsTokenList": [
      {
        "name": "EIGEN",
        "symbol": "EIGEN",
        "decimals": 18,
        "apiID": "eigenlayer",
        "group": "EIGEN",
        "rebasing": false,
        "address": "0xec53bf9167f50cdeb3ae105f56099aaab9061f83",
        "strategyAddress": "0xaCB55C530Acdb2849e6d4f36992Cd8c9D50ED8F7",
        "usedForPoints": false,
        "about": "EIGEN is the universal intersubjective work token that significantly expands the types of credible commitments that any staker can make with cryptoeconomic security. This massively expands the surface area of digital tasks that can be secured on Ethereum beyond just tasks of type \"objectively attributable\" that was offered by simple restaking. Now, oracles, DA, off-chain VM such as AI or gaming engines, censorship-resistance module, etc., can be secured via EIGEN. The intersubjective nature of EIGEN enables raising challenges against malicious EIGEN stakers/operators that can trigger intersubjective forking of the token. Please see the whitepaper for detailed overview of the risks associated with such intersubjective forking."
      },
      {
        "name": "Wrapped Ether",
        "symbol": "WETH",
        "decimals": 18,
        "apiID": "weth",
        "group": "WETH",
        "rebasing": false,
        "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        "usedForPoints": false,
        "about": "WETH is an ERC-20 token on Ethereum that represents 1 Ether (ETH). ETH is used to pay for transactions on the Ethereum blockchain. For example, swapping between cryptoassets on an Ethereum-based DEX will cost some small amount of ETH"
      },
      {
        "name": "Threshold Network",
        "symbol": "T",
        "decimals": 18,
        "apiID": "threshold-network-token",
        "group": "token",
        "rebasing": false,
        "address": "0xcdf7028ceab81fa0c6971208e83fa7872994bee5",
        "about": ""
      }
    ]
  },
  "testnetHolesky": {
    "eigenPodManagerAddress": "0x30770d7E3e71112d7A6b7259542D1f680a70e315",
    "strategyManagerAddress": "0xdfB5f6CE42aAA7830E94ECFCcAd411beF4d4D5b6",
    "delayedWithdrawalRouterAddress": "0x642c646053eaf2254f088e9019ACD73d9AE0FA32",
    "delegationManagerAddress": "0xA44151489861Fe9e3055d95adC98FbD462B948e7",
    "eigendaServiceManagerAddress": "0xD4A7E1Bd8015057293f0D0A557088c286942e84b",
    "rewardsCoordinatorAddress": "0xAcc1fb458a1317E886dB376Fc8141540537E68fE",
    "stakingTokenList": [
      {
        "name": "EIGEN",
        "symbol": "EIGEN",
        "decimals": 18,
        "apiID": "eigenlayer",
        "group": "EIGEN",
        "rebasing": false,
        "address": "0x3B78576F7D6837500bA3De27A60c7f594934027E",
        "strategyAddress": "0x43252609bff8a13dFe5e057097f2f45A24387a84",
        "usedForPoints": false,
        "about": "EIGEN is the universal intersubjective work token that significantly expands the types of credible commitments that any staker can make with cryptoeconomic security. This massively expands the surface area of digital tasks that can be secured on Ethereum beyond just tasks of type \"objectively attributable\" that was offered by simple restaking. Now, oracles, DA, off-chain VM such as AI or gaming engines, censorship-resistance module, etc., can be secured via EIGEN. The intersubjective nature of EIGEN enables raising challenges against malicious EIGEN stakers/operators that can trigger intersubjective forking of the token. Please see the whitepaper for detailed overview of the risks associated with such intersubjective forking."
      },
      {
        "name": "eOracle",
        "symbol": "EO",
        "decimals": 18,
        "apiID": null,
        "group": "EO",
        "rebasing": false,
        "address": "0x89Aa2dE0beC1b85c1A73111aee7E9A3EE3CBb593",
        "strategyAddress": "0x78dBcbEF8fF94eC7F631c23d38d197744a323868",
        "usedForPoints": false,
        "about": "The EO token is a work token responsible for managing the security, incentives, and penalties for operators within the eOracle chain. Additionally, it functions as the governance token for the eOracle DAO, providing holders with the ability to participate in decision-making processes.",
        "twitter": "https://x.com/eoracle_network",
        "website": "https://eoracle.io/"
      },
      {
        "name": "Restaked ALT",
        "symbol": "reALT",
        "decimals": 18,
        "apiID": null,
        "group": "reALT",
        "rebasing": false,
        "address": "0x2ff89Aa21D2FB7B00F28A3d224ECf5854ea162f4",
        "strategyAddress": "0xAD76D205564f955A9c18103C4422D1Cd94016899",
        "usedForPoints": false,
        "about": "reALT, restaked ALT token, is an auto-compounding ERC20 share token that captures the ALT staking contribution of a holder and accumulates the compounding rewards earned."
      },
      {
        "name": "Rocket Pool Ether",
        "symbol": "rETH",
        "decimals": 18,
        "apiID": "rocket-pool-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0x7322c24752f79c05FFD1E2a6FCB97020C1C264F1",
        "strategyAddress": "0x3A8fBdf9e77DFc25d09741f51d3E181b25d0c4E0",
        "usedForPoints": true,
        "about": "Rocket Pool is a decentralized staking pool protocol for Ethereum. The Rocket Pool ETH (rETH) token is a liquid staking token within the Rocket Pool protocol. The rETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases. rETH had 2224 node operators running 6 validators each on average, as of Q1 2023. To restake rETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/rocket_pool",
        "website": "https://rocketpool.net"
      },
      {
        "name": "Lido Staked Ether",
        "symbol": "stETH",
        "decimals": 18,
        "apiID": "staked-ether",
        "group": "lst",
        "rebasing": true,
        "address": "0x3F1c547b21f65e10480dE3ad8E19fAAC46C95034",
        "strategyAddress": "0x7D704507b76571a51d9caE8AdDAbBFd0ba0e63d3",
        "usedForPoints": true,
        "about": "stETH is a token that represents staked Ether in the Lido staking pool protocol. stETH tokens are issued 1:1 to staked Ether. When token rewards are earned from the Ethereum Proof-of-Stake consensus mechanism, stETH token balances increase for each holder. stETH had 30 node operators running 5885 validators each on average, as of Q1 2023. To restake stETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/LidoFinance",
        "website": "https://lido.fi/"
      },
      {
        "name": "Wrapped Ether",
        "symbol": "WETH",
        "decimals": 18,
        "apiID": "weth",
        "group": "WETH",
        "rebasing": false,
        "address": "0x94373a4919B3240D86eA41593D5eBa789FEF3848",
        "strategyAddress": "0x80528D6e9A2BAbFc766965E0E26d5aB08D9CFaF9",
        "usedForPoints": false,
        "about": "WETH is an ERC-20 token on Ethereum that represents 1 Ether (ETH). ETH is used to pay for transactions on the Ethereum blockchain. For example, swapping between cryptoassets on an Ethereum-based DEX will cost some small amount of ETH"
      },
      {
        "name": "Liquid Staked Ether",
        "symbol": "LsETH",
        "decimals": 18,
        "apiID": "liquid-staked-ethereum",
        "group": "lst",
        "rebasing": false,
        "address": "0x1d8b30cC38Dba8aBce1ac29Ea27d9cFd05379A09",
        "strategyAddress": "0x05037A81BD7B4C9E0F7B430f1F2A22c31a2FD943",
        "usedForPoints": true,
        "about": "LsETH is the LST of Liquid Collective, a decentralized liquid staking protocol. Each LsETH token represents your ownership of staked ETH plus network and MEV rewards accrued. Supported across a broad ecosystem of leading platforms and custodians, LsETH features automatic reward restaking, enterprise-grade security, Node Operator performance agreements, and built-in slashing coverage.",
        "twitter": "https://twitter.com/liquid_col",
        "website": "https://liquidcollective.io"
      },
      {
        "name": "Stader Staked Ether",
        "symbol": "ETHx",
        "decimals": 18,
        "apiID": "stader-ethx",
        "group": "lst",
        "rebasing": false,
        "address": "0xB4F5fc289a778B80392b86fa70A7111E5bE0F859",
        "strategyAddress": "0x31B6F59e1627cEfC9fA174aD03859fC337666af7",
        "usedForPoints": true,
        "about": "Stader's ETHx is a liquid staking token on Ethereum, offering users the ability to earn staking rewards. The ETHx token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. It employs a multi-pool architecture, dividing staked Ethereum between Permissionless and Permissioned node operator pools, ensuring scalability and decentralization.",
        "twitter": "https://twitter.com/staderlabs_eth",
        "website": "https://staderlabs.com/eth"
      },
      {
        "name": "StakeWise Staked Ether",
        "symbol": "osETH",
        "decimals": 18,
        "apiID": "stakewise-v3-oseth",
        "group": "lst",
        "rebasing": false,
        "address": "0xF603c5A3F774F05d4D848A9bB139809790890864",
        "strategyAddress": "0x46281E3B7fDcACdBa44CADf069a94a588Fd4C6Ef",
        "usedForPoints": true,
        "about": "osETH is the slashing-protected liquid staking token representing capital staked on StakeWise, a staking marketplace that allows anyone to stake on their own terms. The osETH conversion rate changes over time based on the underlying staked ETH rewards and penalties. To restake osETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/stakewise_io",
        "website": "https://stakewise.io"
      },
      {
        "name": "Mantle Staked Ether",
        "symbol": "mETH",
        "decimals": 18,
        "apiID": "mantle-staked-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xe3C063B1BEe9de02eb28352b55D49D85514C67FF",
        "strategyAddress": "0xaccc5A86732BE85b5012e8614AF237801636F8e5",
        "usedForPoints": true,
        "about": "Mantle LSP is a permissionless and non-custodial ETH liquid staking protocol deployed on Ethereum L1 and governed by Mantle. Mantle LSP combines simple and modern design with robust risk management, and leverages extensive Mantle Ecosystem resources to deliver a highly rewarding experience and supercharges Mantle’s status as a yield powerhouse. Mantle Staked Ether ($mETH) serves as the value-accumulating receipt token of Mantle LSP.",
        "twitter": "https://twitter.com/0xMantle",
        "website": "https://www.mantle.xyz/meth"
      },
      {
        "name": "Ankr Staked Ether",
        "symbol": "ankrETH",
        "decimals": 18,
        "apiID": "ankreth",
        "group": "lst",
        "rebasing": false,
        "address": "0x8783c9c904e1bdc87d9168ae703c8481e8a477fd",
        "strategyAddress": "0x7673a47463F80c6a3553Db9E54c8cDcd5313d0ac",
        "usedForPoints": true,
        "about": "Ankr Staking is a pioneer in liquid staking, having launched the first ETH Liquid Staking Token (LST) in December 2020. The Ankr Staked ETH (ankrETH) token is a liquid staking token within Ankr Staking Protocol. The ankrETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases.",
        "twitter": "https://twitter.com/ankr",
        "website": "https://www.ankr.com/staking-crypto"
      },
      {
        "name": "Staked Frax Ether",
        "symbol": "sfrxETH",
        "decimals": 18,
        "apiID": "staked-frax-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xa63f56985F9C7F3bc9fFc5685535649e0C1a55f3",
        "strategyAddress": "0x9281ff96637710Cd9A5CAcce9c6FAD8C9F54631c",
        "usedForPoints": true,
        "about": "Frax Ether is a decentralized liquid staking derivative designed to uniquely leverage the Frax Finance ecosystem. It is issued and created by the Frax Protocol and has a unique two token system with all validator income going to sfrxETH token holders. The sfrxETH token slowly increases in price over time as a function of validator income of the protocol and is always redeemable for the underlying ETH that backs it.",
        "twitter": "https://twitter.com/fraxfinance",
        "website": "https://frax.finance"
      }
    ],
    "nativeToken": {
      "name": "Natively Staked Ether",
      "symbol": "ETH",
      "decimals": 18,
      "apiID": "ethereum",
      "group": "native",
      "rebasing": false,
      "address": "0x0000000000000000000000000000000000000000",
      "strategyAddress": "0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0",
      "usedForPoints": true,
      "about": "Native Restaking means running one or more Ethereum beacon chain validators while connected to the EigenLayer protocol. In order to participate in Native Restaking, you must run at least one Ethereum validator and set its withdrawal address to your EigenPod. \n\nOnce your EigenPod is deployed and your validator(s) are running, the beacon chain will generate rewards for your validators and deposit them into your EigenPod. As these rewards accumulate in your pod, you can restake them to earn additional rewards through EigenLayer or you can withdraw them to your personal wallet."
    },
    "rewardsTokenList": [
      {
        "name": "EIGEN",
        "symbol": "EIGEN",
        "decimals": 18,
        "apiID": "eigenlayer",
        "group": "EIGEN",
        "rebasing": false,
        "address": "0x3B78576F7D6837500bA3De27A60c7f594934027E",
        "strategyAddress": "0x43252609bff8a13dFe5e057097f2f45A24387a84",
        "usedForPoints": false,
        "about": "EIGEN is the universal intersubjective work token that significantly expands the types of credible commitments that any staker can make with cryptoeconomic security. This massively expands the surface area of digital tasks that can be secured on Ethereum beyond just tasks of type \"objectively attributable\" that was offered by simple restaking. Now, oracles, DA, off-chain VM such as AI or gaming engines, censorship-resistance module, etc., can be secured via EIGEN. The intersubjective nature of EIGEN enables raising challenges against malicious EIGEN stakers/operators that can trigger intersubjective forking of the token. Please see the whitepaper for detailed overview of the risks associated with such intersubjective forking."
      },
      {
        "name": "AltLayer Inu",
        "symbol": "ALTINU",
        "decimals": 18,
        "apiID": "",
        "group": "token",
        "rebasing": false,
        "address": "0x4Bd30dAf919a3f74ec57f0557716Bcc660251Ec0",
        "usedForPoints": false,
        "about": "AltLayer Inu is testnet reward token for AltLayer managed AVS."
      },
      {
        "name": "TestRoute",
        "symbol": "TRoute",
        "decimals": "18",
        "apiID": "",
        "group": "token",
        "rebasing": false,
        "address": "0xb160f9fE71c32DFCfaFCdC988D89001698eabd48",
        "usedForPoints": false,
        "about": "TestRoute native token of Router Chain Alpha Devnet"
      },
      {
        "name": "Eigen Inu",
        "symbol": "EINU",
        "decimals": 18,
        "apiID": "",
        "group": "token",
        "rebasing": false,
        "address": "0xdeeeeE2b48C121e6728ed95c860e296177849932",
        "usedForPoints": false,
        "about": "The Eigen Inus are In Control"
      }
    ]
  },
  "preprodHolesky": {
    "eigenPodManagerAddress": "0xB8d8952f572e67B11e43bC21250967772fa883Ff",
    "strategyManagerAddress": "0xF9fbF2e35D8803273E214c99BF15174139f4E67a",
    "delayedWithdrawalRouterAddress": "0xC4BC46a87A67a531eCF7f74338E1FA79533334Fa",
    "delegationManagerAddress": "0x75dfE5B44C2E530568001400D3f704bC8AE350CC",
    "eigendaServiceManagerAddress": "0x54A03db2784E3D0aCC08344D05385d0b62d4F432",
    "rewardsCoordinatorAddress": "0xb22Ef643e1E067c994019A4C19e403253C05c2B0",
    "stakingTokenList": [
      {
        "name": "EIGEN",
        "symbol": "EIGEN",
        "decimals": 18,
        "apiID": "eigenlayer",
        "group": "EIGEN",
        "rebasing": false,
        "address": "0xD58f6844f79eB1fbd9f7091d05f7cb30d3363926",
        "strategyAddress": "0xdcCF401fD121d8C542E96BC1d0078884422aFAD2",
        "usedForPoints": false,
        "about": "EIGEN is the universal intersubjective work token that significantly expands the types of credible commitments that any staker can make with cryptoeconomic security. This massively expands the surface area of digital tasks that can be secured on Ethereum beyond just tasks of type \"objectively attributable\" that was offered by simple restaking. Now, oracles, DA, off-chain VM such as AI or gaming engines, censorship-resistance module, etc., can be secured via EIGEN. The intersubjective nature of EIGEN enables raising challenges against malicious EIGEN stakers/operators that can trigger intersubjective forking of the token. Please see the whitepaper for detailed overview of the risks associated with such intersubjective forking."
      },
      {
        "name": "Wrapped Ether Q2",
        "symbol": "WETHQ2",
        "decimals": 18,
        "apiID": null,
        "group": "WETHQ2",
        "rebasing": false,
        "address": "0x1aea86558d3FF59176Fe7D5BE48B59B09c96bbf7",
        "strategyAddress": "0x6E5D5060B33ca2090A78E9cb74Fe207453b30E49",
        "usedForPoints": false,
        "about": "WETHQ2 is a fork of WETH, an ERC-20 token on Ethereum that represents 1 Ether (ETH)"
      },
      {
        "name": "Rocket Pool Ether",
        "symbol": "rETH",
        "decimals": 18,
        "apiID": "rocket-pool-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0x7322c24752f79c05FFD1E2a6FCB97020C1C264F1",
        "strategyAddress": "0x87f6C7d24b109919eB38295e3F8298425e6331D9",
        "usedForPoints": true,
        "about": "Rocket Pool is a decentralized staking pool protocol for Ethereum. The Rocket Pool ETH (rETH) token is a liquid staking token within the Rocket Pool protocol. The rETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases. rETH had 2224 node operators running 6 validators each on average, as of Q1 2023. To restake rETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/rocket_pool",
        "website": "https://rocketpool.net"
      },
      {
        "name": "Lido Staked Ether",
        "symbol": "stETH",
        "decimals": 18,
        "apiID": "staked-ether",
        "group": "lst",
        "rebasing": true,
        "address": "0x3F1c547b21f65e10480dE3ad8E19fAAC46C95034",
        "strategyAddress": "0x5C8b55722f421556a2AAfb7A3EA63d4c3e514312",
        "usedForPoints": true,
        "about": "stETH is a token that represents staked Ether in the Lido staking pool protocol. stETH tokens are issued 1:1 to staked Ether. When token rewards are earned from the Ethereum Proof-of-Stake consensus mechanism, stETH token balances increase for each holder. stETH had 30 node operators running 5885 validators each on average, as of Q1 2023. To restake stETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/LidoFinance",
        "website": "https://lido.fi/"
      },
      {
        "name": "Wrapped Ether",
        "symbol": "WETH",
        "decimals": 18,
        "apiID": "weth",
        "group": "WETH",
        "rebasing": false,
        "address": "0x94373a4919B3240D86eA41593D5eBa789FEF3848",
        "strategyAddress": "0xD523267698C81a372191136e477fdebFa33D9FB4",
        "usedForPoints": false,
        "about": "WETH is an ERC-20 token on Ethereum that represents 1 Ether (ETH). ETH is used to pay for transactions on the Ethereum blockchain. For example, swapping between cryptoassets on an Ethereum-based DEX will cost some small amount of ETH"
      },
      {
        "name": "Liquid Staked Ether",
        "symbol": "LsETH",
        "decimals": 18,
        "apiID": "liquid-staked-ethereum",
        "group": "lst",
        "rebasing": false,
        "address": "0x1d8b30cC38Dba8aBce1ac29Ea27d9cFd05379A09",
        "strategyAddress": "0x3c28437E610fB099Cc3d6De4D9c707DFACD308AE",
        "usedForPoints": true,
        "about": "LsETH is the LST of Liquid Collective, a decentralized liquid staking protocol. Each LsETH token represents your ownership of staked ETH plus network and MEV rewards accrued. Supported across a broad ecosystem of leading platforms and custodians, LsETH features automatic reward restaking, enterprise-grade security, Node Operator performance agreements, and built-in slashing coverage.",
        "twitter": "https://twitter.com/liquid_col",
        "website": "https://liquidcollective.io"
      },
      {
        "name": "Stader Staked Ether",
        "symbol": "ETHx",
        "decimals": 18,
        "apiID": "stader-ethx",
        "group": "lst",
        "rebasing": false,
        "address": "0xB4F5fc289a778B80392b86fa70A7111E5bE0F859",
        "strategyAddress": "0x3cb1fD19CFb178C1098f2fc1e11090A0642B2314",
        "usedForPoints": true,
        "about": "Stader's ETHx is a liquid staking token on Ethereum, offering users the ability to earn staking rewards. The ETHx token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. It employs a multi-pool architecture, dividing staked Ethereum between Permissionless and Permissioned node operator pools, ensuring scalability and decentralization.",
        "twitter": "https://twitter.com/staderlabs_eth",
        "website": "https://staderlabs.com/eth"
      },
      {
        "name": "StakeWise Staked Ether",
        "symbol": "osETH",
        "decimals": 18,
        "apiID": "stakewise-v3-oseth",
        "group": "lst",
        "rebasing": false,
        "address": "0xF603c5A3F774F05d4D848A9bB139809790890864",
        "strategyAddress": "0x6dC6cE589F852F96ac86cB160AB0B15b9f56DeDd",
        "usedForPoints": true,
        "about": "osETH is the slashing-protected liquid staking token representing capital staked on StakeWise, a staking marketplace that allows anyone to stake on their own terms. The osETH conversion rate changes over time based on the underlying staked ETH rewards and penalties. To restake osETH, deposit the tokens in the EigenLayer contract.",
        "twitter": "https://twitter.com/stakewise_io",
        "website": "https://stakewise.io"
      },
      {
        "name": "Mantle Staked Ether",
        "symbol": "mETH",
        "decimals": 18,
        "apiID": "mantle-staked-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xe3C063B1BEe9de02eb28352b55D49D85514C67FF",
        "strategyAddress": "0x24DA526F9e465c4fb6BAe41E226Df8aA5b34eAc7",
        "usedForPoints": true,
        "about": "Mantle LSP is a permissionless and non-custodial ETH liquid staking protocol deployed on Ethereum L1 and governed by Mantle. Mantle LSP combines simple and modern design with robust risk management, and leverages extensive Mantle Ecosystem resources to deliver a highly rewarding experience and supercharges Mantle’s status as a yield powerhouse. Mantle Staked Ether ($mETH) serves as the value-accumulating receipt token of Mantle LSP.",
        "twitter": "https://twitter.com/0xMantle",
        "website": "https://www.mantle.xyz/meth"
      },
      {
        "name": "Ankr Staked Ether",
        "symbol": "ankrETH",
        "decimals": 18,
        "apiID": "ankreth",
        "group": "lst",
        "rebasing": false,
        "address": "0x8783c9c904e1bdc87d9168ae703c8481e8a477fd",
        "strategyAddress": "0x7fA77c321bf66e42eaBC9b10129304F7f90c5585",
        "usedForPoints": true,
        "about": "Ankr Staking is a pioneer in liquid staking, having launched the first ETH Liquid Staking Token (LST) in December 2020. The Ankr Staked ETH (ankrETH) token is a liquid staking token within Ankr Staking Protocol. The ankrETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases.",
        "twitter": "https://twitter.com/ankr",
        "website": "https://www.ankr.com/staking-crypto"
      },
      {
        "name": "Staked Frax Ether",
        "symbol": "sfrxETH",
        "decimals": 18,
        "apiID": "staked-frax-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0xa63f56985F9C7F3bc9fFc5685535649e0C1a55f3",
        "strategyAddress": "0xf6a09ae03D7760aEcf1626Ce7Df0F113BEC2d9bD",
        "usedForPoints": true,
        "about": "Frax Ether is a decentralized liquid staking derivative designed to uniquely leverage the Frax Finance ecosystem. It is issued and created by the Frax Protocol and has a unique two token system with all validator income going to sfrxETH token holders. The sfrxETH token slowly increases in price over time as a function of validator income of the protocol and is always redeemable for the underlying ETH that backs it.",
        "twitter": "https://twitter.com/fraxfinance",
        "website": "https://frax.finance"
      }
    ],
    "nativeToken": {
      "name": "Natively Staked Ether",
      "symbol": "ETH",
      "decimals": 18,
      "apiID": "ethereum",
      "group": "native",
      "rebasing": false,
      "address": "0x0000000000000000000000000000000000000000",
      "strategyAddress": "0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0",
      "usedForPoints": true,
      "about": "Native Restaking means running one or more Ethereum beacon chain validators while connected to the EigenLayer protocol. In order to participate in Native Restaking, you must run at least one Ethereum validator and set its withdrawal address to your EigenPod. \n\nOnce your EigenPod is deployed and your validator(s) are running, the beacon chain will generate rewards for your validators and deposit them into your EigenPod. As these rewards accumulate in your pod, you can restake them to earn additional rewards through EigenLayer or you can withdraw them to your personal wallet."
    },
    "rewardsTokenList": [
      {
        "name": "Eigen Inu",
        "symbol": "EINU",
        "decimals": 18,
        "apiID": "",
        "group": "token",
        "rebasing": false,
        "address": "0xdeeeeE2b48C121e6728ed95c860e296177849932",
        "usedForPoints": false,
        "about": "The Eigen Inus are In Control"
      }
    ]
  },
  "test": {
    "eigenPodManagerAddress": "0x33e42d539abFe9b387B27b0e467374Bbb76cf925",
    "strategyManagerAddress": "0xD309ADd2B269d522112DcEe0dCf0b0f04a09C29e",
    "delayedWithdrawalRouterAddress": "0x9572e46797B7A07257314e587061dC46c4dfCE0E",
    "delegationManagerAddress": "0x45b4c4DAE69393f62e1d14C5fe375792DF4E6332",
    "eigendaServiceManagerAddress": "0xa3b1689ab85409b15e07d2ed50a6ea9905074ee5",
    "stakingTokenList": [
      {
        "name": "Rocket Pool Ether",
        "symbol": "rETH",
        "decimals": 18,
        "apiID": "rocket-pool-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0x17e8d81701556f0d27bE2617E47A8FE15682A6a5",
        "strategyAddress": "0xE2a9d81701556f0d27Be2617E47a8FE15682a6a5",
        "about": "Rocket Pool is a decentralized staking pool protocol for Ethereum. The Rocket Pool ETH (rETH) token is a liquid staking token within the Rocket Pool protocol. The rETH token represents an amount of ETH that is being staked and rewarded within the Ethereum Proof-of-Stake consensus mechanism. When rewards are earned, the amount due to each token increases. rETH had 2224 node operators running 6 validators each on average, as of Q1 2023. To restake rETH, deposit the tokens in the EigenLayer contract.",
        "usedForPoints": true,
        "twitter": "https://twitter.com/rocket_pool",
        "website": "https://rocketpool.net"
      },
      {
        "name": "Lido Staked Ether",
        "symbol": "stETH",
        "decimals": 18,
        "apiID": "staked-ether",
        "group": "lst",
        "rebasing": true,
        "address": "0xA7f31604Ad319973f9ee6a1E14d0410989868f1c",
        "strategyAddress": "0x87f31604aD319973f9EE6A1e14d0410989868F1c",
        "about": "stETH is a token that represents staked Ether in the Lido staking pool protocol. stETH tokens are issued 1:1 to staked Ether. When token rewards are earned from the Ethereum Proof-of-Stake consensus mechanism, stETH token balances increase for each holder. stETH had 30 node operators running 5885 validators each on average, as of Q1 2023. To restake stETH, deposit the tokens in the EigenLayer contract.",
        "usedForPoints": true,
        "twitter": "https://twitter.com/LidoFinance",
        "website": "https://lido.fi/"
      },
      {
        "name": "Binance Staked Ether",
        "symbol": "wBETH",
        "decimals": 18,
        "apiID": "wrapped-beacon-eth",
        "group": "lst",
        "rebasing": false,
        "address": "0x34F31604AD319973f9eE6A1e14D0410989868f1c",
        "strategyAddress": "0x44F31604AD319973f9Ee6a1E14D0410989868F1c",
        "about": "Wrapped Beacon ETH (WBETH) is a value-accruing liquid staking token, where 1 WBETH represents 1 staked ETH, along with the staking rewards accrued on ETH Staking. You can use WBETH to interact with products on Binance and DeFi protocols, while still receiving the corresponding ETH Staking rewards accrued. You can stake ETH to WBETH or redeem your WBETH to ETH on the Binance ETH Staking page at zero fees.",
        "usedForPoints": true,
        "twitter": "https://twitter.com/binance",
        "website": "https://www.binance.com/en/ethereum-staking"
      },
      {
        "name": "Mantle Staked Ether",
        "symbol": "mETH",
        "decimals": 18,
        "apiID": "mantle-staked-ether",
        "group": "lst",
        "rebasing": false,
        "address": "0x5CF31604aD319973F9ee6a1e14d0410989868f1c",
        "strategyAddress": "0xccF31604AD319973F9ee6A1E14D0410989868F1c",
        "about": "Mantle LSP is a permissionless and non-custodial ETH liquid staking protocol deployed on Ethereum L1 and governed by Mantle. Mantle LSP combines simple and modern design with robust risk management, and leverages extensive Mantle Ecosystem resources to deliver a highly rewarding experience and supercharges Mantle’s status as a yield powerhouse. Mantle Staked Ether ($mETH) serves as the value-accumulating receipt token of Mantle LSP.",
        "usedForPoints": false,
        "twitter": "https://twitter.com/0xMantle",
        "website": "https://www.mantle.xyz/meth"
      }
    ],
    "nativeToken": {
      "name": "Natively Staked Ether",
      "symbol": "ETH",
      "decimals": 18,
      "apiID": "ethereum",
      "group": "lst",
      "rebasing": false,
      "address": "0x0000000000000000000000000000000000000000",
      "strategyAddress": "0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0",
      "about": "Native Restaking means running one or more Ethereum beacon chain validators while connected to the EigenLayer protocol. In order to participate in Native Restaking, you must run at least one Ethereum validator and set its withdrawal address to your EigenPod. \n\nOnce your EigenPod is deployed and your validator(s) are running, the beacon chain will generate rewards for your validators and deposit them into your EigenPod. As these rewards accumulate in your pod, you can restake them to earn additional rewards through EigenLayer or you can withdraw them to your personal wallet.",
      "usedForPoints": false
    }
  }
}
