'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';

import { createWagmiConfig, Web3Provider } from '@layr-labs/eigen-kit/react';
import { PROJECT_ID } from '@layr-labs/eigen-kit/configs';

import { ABCRepro } from 'fonts/localFont';

import { env } from '@env';

import type { Config } from 'wagmi';

const rpcs = [
  env.NEXT_PUBLIC_ALCHEMY_API_KEY &&
    `https://eth-${
      env.NEXT_PUBLIC_CHAIN_ID === '1' ? 'mainnet' : 'holesky'
    }.g.alchemy.com/v2/${env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
].filter(Boolean);

export const config = createWagmiConfig(
  env.NEXT_PUBLIC_CHAIN_ID as '1' | '17000',
  PROJECT_ID,
  {
    name: 'EigenLayer',
    description: 'EigenLayer',
    url: 'app.eigenlayer.xyz',
    icons: ['https://avatars.githubusercontent.com/u/92827658'],
  },
  rpcs,
);

function WagmiProviderWrapper({ config, children }: { config: Config; children: React.ReactNode }) {
  return <WagmiProvider config={config}>{children}</WagmiProvider>;
}

export function Web3WagmiProvider({ children }: { children: React.ReactNode }) {
  return (
    <Web3Provider
      projectId={PROJECT_ID}
      WagmiProvider={WagmiProviderWrapper}
      wagmiConfig={config}
      QueryClientProvider={QueryClientProvider}
      themeVariables={{ '--w3m-font-family': ABCRepro.style.fontFamily }}
    >
      {children}
    </Web3Provider>
  );
}
