/* eslint-disable turbo/no-undeclared-env-vars */
import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    // DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    NEXT_PUBLIC_CHAIN_ID: z.string().min(1),
    COINGECKO_API_KEY: z.string().min(1),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI: z.string().min(1),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2: z.string().min(1),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2_REST: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'production', 'preview']),
    CHAINALYSIS_API_KEY: z.string().min(1),
    NEXT_PUBLIC_CHAINALYSIS_URL: z.string().min(1),
    OPERATOR_BLOCKLIST: z.string(),
    NEXT_PUBLIC_SANCTIONS_ON: z.boolean(),
    NEXT_PUBLIC_RESTAKE_DISPLAY: z.object({
      price: z.array(z.object({})),
    }),
    AVS_ALLOWLIST: z.string(),
    NEXT_PUBLIC_CONTRACT_ENV: z.enum(['mainnet-ethereum', 'testnet-holesky', 'preprod-holesky']),
    STAKER_ADDRESS_ALLOWLIST: z.string().optional(),
    NEXT_PUBLIC_PROOFS_SERVICE_URL: z.string().min(1),
    NEXT_PUBLIC_DD_RUM_APP_ID: z.string(),
    NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN: z.string(),
    NEXT_PUBLIC_DD_RUM_SAMPLE_RATE: z.number(),
    NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE: z.number(),
    NEXT_PUBLIC_DD_RUM_ENV: z.string(),
    TOKEN_BANLIST: z.string(),
    NEXT_PUBLIC_LONGTAIL_VERSION: z.enum(['v0', 'v1', 'v2']),
    EPI_URL: z.string().min(1),
    NEXT_PUBLIC_GA_TAG: z.string().min(1).optional(),
    NEXT_PUBLIC_SLASHING_VERSION: z.enum(['m0', 'm1', 'm2', 'm3', 'm4']),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_BLOCK_EXPLORER_URL: z.string().min(1),
    NEXT_PUBLIC_CHAIN_ID: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'production', 'preview']),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI: z.string().min(1),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2: z.string().min(1),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2_REST: z.string().min(1),
    NEXT_PUBLIC_SANCTIONS_ON: z.boolean(),
    NEXT_PUBLIC_ALCHEMY_API_KEY: z.string(),
    NEXT_PUBLIC_RESTAKE_DISPLAY: z.object({
      display_order: z.string().array(),
    }),
    NEXT_PUBLIC_CENTRALIZED_RISK: z.number().min(0),
    NEXT_PUBLIC_CONTRACT_ENV: z.enum(['mainnet-ethereum', 'testnet-holesky', 'preprod-holesky']),
    NEXT_PUBLIC_CENTRALIZED_RISK_TOGGLE: z.boolean(),
    NEXT_PUBLIC_PROOFS_SERVICE_URL: z.string().min(1),
    NEXT_PUBLIC_BATCH_SIZE: z.number().min(1),
    NEXT_PUBLIC_DD_RUM_APP_ID: z.string(),
    NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN: z.string(),
    NEXT_PUBLIC_DD_RUM_SAMPLE_RATE: z.number(),
    NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE: z.number(),
    NEXT_PUBLIC_DD_RUM_ENV: z.string(),
    NEXT_PUBLIC_INCENTIVES_VERSION: z.enum(['v0', 'v1', 'v2']),
    NEXT_PUBLIC_LONGTAIL_VERSION: z.enum(['v0', 'v1', 'v2']),
    NEXT_PUBLIC_GA_TAG: z.string().min(1).optional(),
    NEXT_PUBLIC_SLASHING_VERSION: z.enum(['m0', 'm1', 'm2', 'm3', 'm4']),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    // DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI: process.env.NEXT_PUBLIC_EIGENLAYER_BACKEND_URI,
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2_REST: (
      process.env.NEXT_PUBLIC_EIGENLAYER_BACKEND_URI || ''
    ).replace(/v1$/, 'v2'),
    NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2: process.env.NEXT_PUBLIC_EIGENLAYER_BACKEND_URI_V2,
    NEXT_PUBLIC_BLOCK_EXPLORER_URL: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL,
    NEXT_PUBLIC_CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
    NEXT_PUBLIC_ALCHEMY_API_KEY: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || '',
    COINGECKO_API_KEY: process.env.COINGECKO_API_KEY,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    CHAINALYSIS_API_KEY: process.env.CHAINALYSIS_API_KEY,
    NEXT_PUBLIC_CHAINALYSIS_URL: process.env.NEXT_PUBLIC_CHAINALYSIS_URL,
    NEXT_PUBLIC_SANCTIONS_ON: process.env.NEXT_PUBLIC_SANCTIONS_ON === 'true',
    OPERATOR_BLOCKLIST: process.env.OPERATOR_BLOCKLIST,
    NEXT_PUBLIC_RESTAKE_DISPLAY: process.env.NEXT_PUBLIC_RESTAKE_DISPLAY
      ? JSON.parse(process.env.NEXT_PUBLIC_RESTAKE_DISPLAY)
      : {},
    AVS_ALLOWLIST: process.env.AVS_ALLOWLIST,
    STAKER_ADDRESS_ALLOWLIST: process.env.STAKER_ADDRESS_ALLOWLIST,
    NEXT_PUBLIC_CONTRACT_ENV: process.env.NEXT_PUBLIC_CONTRACT_ENV,
    NEXT_PUBLIC_CENTRALIZED_RISK: process.env.NEXT_PUBLIC_CENTRALIZED_RISK
      ? parseFloat(process.env.NEXT_PUBLIC_CENTRALIZED_RISK)
      : 0.1,
    NEXT_PUBLIC_CENTRALIZED_RISK_TOGGLE: process.env.NEXT_PUBLIC_CENTRALIZED_RISK_TOGGLE === 'true',
    NEXT_PUBLIC_PROOFS_SERVICE_URL: process.env.NEXT_PUBLIC_PROOFS_SERVICE_URL,
    NEXT_PUBLIC_BATCH_SIZE: parseInt(process.env.NEXT_PUBLIC_BATCH_SIZE || '25'),
    NEXT_PUBLIC_DD_RUM_APP_ID: process.env.NEXT_PUBLIC_DD_RUM_APP_ID,
    NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
    NEXT_PUBLIC_DD_RUM_SAMPLE_RATE: Number(process.env.NEXT_PUBLIC_DD_RUM_SAMPLE_RATE),
    NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE: Number(
      process.env.NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE,
    ),
    NEXT_PUBLIC_DD_RUM_ENV: process.env.NEXT_PUBLIC_DD_RUM_ENV,
    NEXT_PUBLIC_LONGTAIL_VERSION: process.env.NEXT_PUBLIC_LONGTAIL_VERSION || 'v1',
    TOKEN_BANLIST: process.env.TOKEN_BANLIST,
    NEXT_PUBLIC_INCENTIVES_VERSION: process.env.NEXT_PUBLIC_INCENTIVES_VERSION,
    EPI_URL: process.env.EPI_URL,
    NEXT_PUBLIC_GA_TAG: process.env.NEXT_PUBLIC_GA_TAG,
    NEXT_PUBLIC_SLASHING_VERSION: process.env.NEXT_PUBLIC_SLASHING_VERSION || 'm0',
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
