export default [
  {
    type: 'constructor',
    inputs: [
      {
        name: '_ethPOS',
        type: 'address',
        internalType: 'contract IETHPOSDeposit',
      },
      {
        name: '_eigenPodManager',
        type: 'address',
        internalType: 'contract IEigenPodManager',
      },
      {
        name: '_GENESIS_TIME',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'receive',
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'GENESIS_TIME',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'activeValidatorCount',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'checkpointBalanceExitedGwei',
    inputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'currentCheckpoint',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct IEigenPod.Checkpoint',
        components: [
          {
            name: 'beaconBlockRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'proofsRemaining',
            type: 'uint24',
            internalType: 'uint24',
          },
          {
            name: 'podBalanceGwei',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'balanceDeltasGwei',
            type: 'int128',
            internalType: 'int128',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'currentCheckpointTimestamp',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'eigenPodManager',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IEigenPodManager',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'ethPOS',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IETHPOSDeposit',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getParentBlockRoot',
    inputs: [
      {
        name: 'timestamp',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'initialize',
    inputs: [
      {
        name: '_podOwner',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'lastCheckpointTimestamp',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'podOwner',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'recoverTokens',
    inputs: [
      {
        name: 'tokenList',
        type: 'address[]',
        internalType: 'contract IERC20[]',
      },
      {
        name: 'amountsToWithdraw',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: 'recipient',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'stake',
    inputs: [
      {
        name: 'pubkey',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'signature',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'depositDataRoot',
        type: 'bytes32',
        internalType: 'bytes32',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'startCheckpoint',
    inputs: [
      {
        name: 'revertIfNoBalance',
        type: 'bool',
        internalType: 'bool',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'validatorPubkeyHashToInfo',
    inputs: [
      {
        name: 'validatorPubkeyHash',
        type: 'bytes32',
        internalType: 'bytes32',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct IEigenPod.ValidatorInfo',
        components: [
          {
            name: 'validatorIndex',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'restakedBalanceGwei',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'lastCheckpointedAt',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'status',
            type: 'uint8',
            internalType: 'enum IEigenPod.VALIDATOR_STATUS',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'validatorPubkeyToInfo',
    inputs: [
      {
        name: 'validatorPubkey',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct IEigenPod.ValidatorInfo',
        components: [
          {
            name: 'validatorIndex',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'restakedBalanceGwei',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'lastCheckpointedAt',
            type: 'uint64',
            internalType: 'uint64',
          },
          {
            name: 'status',
            type: 'uint8',
            internalType: 'enum IEigenPod.VALIDATOR_STATUS',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'validatorStatus',
    inputs: [
      {
        name: 'validatorPubkey',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum IEigenPod.VALIDATOR_STATUS',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'validatorStatus',
    inputs: [
      {
        name: 'pubkeyHash',
        type: 'bytes32',
        internalType: 'bytes32',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum IEigenPod.VALIDATOR_STATUS',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'verifyCheckpointProofs',
    inputs: [
      {
        name: 'balanceContainerProof',
        type: 'tuple',
        internalType: 'struct BeaconChainProofs.BalanceContainerProof',
        components: [
          {
            name: 'balanceContainerRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'proof',
            type: 'bytes',
            internalType: 'bytes',
          },
        ],
      },
      {
        name: 'proofs',
        type: 'tuple[]',
        internalType: 'struct BeaconChainProofs.BalanceProof[]',
        components: [
          {
            name: 'pubkeyHash',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'balanceRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'proof',
            type: 'bytes',
            internalType: 'bytes',
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'verifyStaleBalance',
    inputs: [
      {
        name: 'beaconTimestamp',
        type: 'uint64',
        internalType: 'uint64',
      },
      {
        name: 'stateRootProof',
        type: 'tuple',
        internalType: 'struct BeaconChainProofs.StateRootProof',
        components: [
          {
            name: 'beaconStateRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'proof',
            type: 'bytes',
            internalType: 'bytes',
          },
        ],
      },
      {
        name: 'proof',
        type: 'tuple',
        internalType: 'struct BeaconChainProofs.ValidatorProof',
        components: [
          {
            name: 'validatorFields',
            type: 'bytes32[]',
            internalType: 'bytes32[]',
          },
          {
            name: 'proof',
            type: 'bytes',
            internalType: 'bytes',
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'verifyWithdrawalCredentials',
    inputs: [
      {
        name: 'beaconTimestamp',
        type: 'uint64',
        internalType: 'uint64',
      },
      {
        name: 'stateRootProof',
        type: 'tuple',
        internalType: 'struct BeaconChainProofs.StateRootProof',
        components: [
          {
            name: 'beaconStateRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'proof',
            type: 'bytes',
            internalType: 'bytes',
          },
        ],
      },
      {
        name: 'validatorIndices',
        type: 'uint40[]',
        internalType: 'uint40[]',
      },
      {
        name: 'validatorFieldsProofs',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
      {
        name: 'validatorFields',
        type: 'bytes32[][]',
        internalType: 'bytes32[][]',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'withdrawRestakedBeaconChainETH',
    inputs: [
      {
        name: 'recipient',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'amountWei',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'withdrawableRestakedExecutionLayerGwei',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint64',
        internalType: 'uint64',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'event',
    name: 'CheckpointCreated',
    inputs: [
      {
        name: 'checkpointTimestamp',
        type: 'uint64',
        indexed: true,
        internalType: 'uint64',
      },
      {
        name: 'beaconBlockRoot',
        type: 'bytes32',
        indexed: true,
        internalType: 'bytes32',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CheckpointFinalized',
    inputs: [
      {
        name: 'checkpointTimestamp',
        type: 'uint64',
        indexed: true,
        internalType: 'uint64',
      },
      {
        name: 'totalShareDeltaWei',
        type: 'int256',
        indexed: false,
        internalType: 'int256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'EigenPodStaked',
    inputs: [
      {
        name: 'pubkey',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Initialized',
    inputs: [
      {
        name: 'version',
        type: 'uint8',
        indexed: false,
        internalType: 'uint8',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'NonBeaconChainETHReceived',
    inputs: [
      {
        name: 'amountReceived',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RestakedBeaconChainETHWithdrawn',
    inputs: [
      {
        name: 'recipient',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'amount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ValidatorBalanceUpdated',
    inputs: [
      {
        name: 'validatorIndex',
        type: 'uint40',
        indexed: false,
        internalType: 'uint40',
      },
      {
        name: 'balanceTimestamp',
        type: 'uint64',
        indexed: false,
        internalType: 'uint64',
      },
      {
        name: 'newValidatorBalanceGwei',
        type: 'uint64',
        indexed: false,
        internalType: 'uint64',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ValidatorCheckpointed',
    inputs: [
      {
        name: 'checkpointTimestamp',
        type: 'uint64',
        indexed: true,
        internalType: 'uint64',
      },
      {
        name: 'validatorIndex',
        type: 'uint40',
        indexed: true,
        internalType: 'uint40',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ValidatorRestaked',
    inputs: [
      {
        name: 'validatorIndex',
        type: 'uint40',
        indexed: false,
        internalType: 'uint40',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ValidatorWithdrawn',
    inputs: [
      {
        name: 'checkpointTimestamp',
        type: 'uint64',
        indexed: true,
        internalType: 'uint64',
      },
      {
        name: 'validatorIndex',
        type: 'uint40',
        indexed: true,
        internalType: 'uint40',
      },
    ],
    anonymous: false,
  },
] as const;
